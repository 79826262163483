import React from 'react'
import { AdvancedVideo } from '@cloudinary/react'
import { Cloudinary } from '@cloudinary/url-gen'
import { fill } from '@cloudinary/url-gen/actions/resize'

interface vid {
    id:string
}

const VideoAds: React.FC<vid> = ({id}) => {
    const cld = new Cloudinary({
        cloud: {
            cloudName:'hubitdev'
        }
    })

    const video = cld.video(id).resize(fill().height(250))

    return <AdvancedVideo cldVid={video} controls autoPlay muted loop />
}

export default VideoAds