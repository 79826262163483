import React from 'react'
import TopStories from './sections/home/topstories'
import Featured from './sections/home/featured'
import NewsTile from '../components/newstile'
import Editorial from './sections/home/editorial'
import MoreNews from './sections/home/morenews'
import MobileMenu from './Mobile/mMenu'

function Home() {
  return (
    <>
        <TopStories />
        <Featured />
        <NewsTile title={"Football"} index={true} />
        <NewsTile title={"Basketball"} index={false} />
        <NewsTile title={"Tennis"} index={true} />
        <Editorial />
        <MoreNews />
    </>
  )
}

export default Home
