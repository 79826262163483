import React from 'react'

function Livescore() {
  return (
    <>
      <div id="wg-api-football-games"
          data-host="v3.football.api-sports.io"
          data-key="dab927106fae8ef1fc05901c3e7af83d"
          data-date=""
          data-league=""
          data-season=""
          data-theme=""
          data-refresh="15"
          data-show-toolbar="true"
          data-show-errors="false"
          data-show-logos="false"
          data-modal-game="true"
          data-modal-standings="true"
          data-modal-show-logos="true">
      </div>
    </>
  )
}

export default Livescore
